import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/how-it-works/hero';
import HowItWillHelp from '../components/how-it-works/how-it-will-help';
import HowResearchWorks from '../components/how-it-works/research';
import TimeWillTell from '../components/how-it-works/time-will-tell';

const FaqPage = () => (
  <Layout>
    <SEO
      title='How to take part'
      description=''
    />
    <Hero />
    <HowItWillHelp />
    <HowResearchWorks />
    <TimeWillTell />
  </Layout>
);

export default FaqPage;
