import React from 'react';

const Hero = () => (
  <div className='how-it-works-hero'>
    <div className='container mx-auto pt-24 lg:pt-32 pb-16 px-8'>
      <div className='lg:w-2/5'>
        <h1 className='font-sans font-bold text-white text-4xl lg:text-5xl leading-very-tight mb-4'>
          How It works
        </h1>

        <p className='font-sans font-normal text-white mb-4'>
          The NL Genome Project is a local research study led by Sequence Bio that aims to learn more about Newfoundland and Labrador’s unique genetic makeup. In this pilot phase, the NL Genome Project will study the DNA, medical records and health information of 10,000 volunteering, consenting participants.
        </p>
      </div>
    </div>
  </div>
);

export default Hero;
