import React from 'react';
import PropTypes from 'prop-types';

const ResearchSection = ({ children, title, image, orientation, containerMargins, titlePadding }) => (
  <div className={`flex justify-between flex-col ${orientation === 'right' ? 'lg:flex-row-reverse' : 'lg:flex-row'} ${containerMargins}`}>
    <div className={`lg:w-3/5 ${titlePadding}`}>
      <h2 className='font-sans font-bold text-blue text-3xl leading-very-tight mb-4'>
        {title}
      </h2>

      <div className='font-sans font-normal text-grey leading-snug'>
        {children}
      </div>
    </div>

    <div className='lg:w-2/5 text-center flex justify-center items-center'>
      <img src={image} alt={title} className='w-4/5' />
    </div>
  </div>
);

ResearchSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  image: PropTypes.string,
  orientation: PropTypes.string,
  containerMargins: PropTypes.string,
  titlePadding: PropTypes.string,
};

ResearchSection.defaultProps = {
  image: '',
  title: '',
  orientation: '',
  containerMargins: 'mb-12',
  titlePadding: 'pt-16',
};

export default ResearchSection;
