import React from 'react';
import arrow from '../../images/arrow.svg';

const HowTheStudyWillHelp = () => (
  <div className='bg-white'>
    <div className='container mx-auto pt-16 px-8'>
      <h2 className='font-sans font-semibold text-blue text-xl lg:text-2xl mb-4'>
        We hope this research will help:
      </h2>
    </div>

    <div className='container mx-auto pb-16 px-8 flex flex-col lg:flex-row'>
      <div className='lg:w-3/5'>

        <ul className='font-sans font-normal text-black mb-4 leading-snug'>
          <li className='flex items-start mb-1'>
            <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
            <span className='flex-1 -mt-px'>
              Understand the genetic makeup of Newfoundland and Labrador
            </span>
          </li>
          <li className='flex items-start mb-1'>
            <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
            <span className='flex-1 -mt-px'>
              Develop better, safer and more targeted medicines
            </span>
          </li>
          <li className='flex items-start mb-1'>
            <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
            <span className='flex-1 -mt-px'>
              Improve how we treat and prevent diseases
            </span>
          </li>
          <li className='flex items-start mb-1'>
            <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
            <span className='flex-1 -mt-px'>
              Inform better healthcare choices by returning findings to participants
            </span>
          </li>
          <li className='flex items-start mb-1'>
            <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
            <span className='flex-1 -mt-px'>
              Integrate precision medicine into our local healthcare system
            </span>
          </li>
          <li className='flex items-start mb-1'>
            <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
            <span className='flex-1 -mt-px'>
              Design a larger research project in Newfoundland and Labrador
            </span>
          </li>
        </ul>

        <p className='font-sans font-normal italic text-xs text-grey leading-snug mb-4'>
          Like other health research, there is no guarantee that this study will result in any significant breakthroughs.
        </p>
      </div>

      <div className ='lg:w-2/5'>
        <p className='font-sans font-normal text-grey leading-snug'>
          Sequence Bio’s Senior Director of Data Science and Analytics, Bari Ballew, PhD, is the Principal Investigator of the study. Sequence Bio’s Director of Research, Gerald Mugford, PhD, and Dr. Dennis O’Keefe are the Co-Principal Investigators.
        </p>
      </div>
    </div>
  </div>
);

export default HowTheStudyWillHelp;
