import React from 'react';
import SubSection from './research/subsection';

const TimeWillTell = () => (
  <>
    <div className='bg-white lg:bg-gradient-b-grey-100-to-transparent lg:pt-24'>

    </div>

    <div className='container mx-auto px-8 lg:flex'>

      <SubSection title='Only time will tell!'>
        <p className='mb-4'>
          With genetic research, we don’t know what we’ll find or discover until we have completed our analysis. That’s why the NL Genome Project will run for a long time and has no set end date. By taking part in this study, you allow us to access your medical records and research your information for your lifetime or for as long as you stay in this study. We will protect your information every step of the way and only use it in ways that you’ve agreed to.
        </p>

        <p className='italic text-xs'>
          If you change your mind about participating in the NL Genome Project, you can withdraw at any time.
        </p>
      </SubSection>

      <SubSection title='Recontact' orientation='right'>
        <p className='mb-4'>
          We’ll be in touch to share updates on the study, and at times we may invite you to provide additional or replacement samples, participate in follow-up interviews, provide additional information through questionnaires, or to take part in new research projects - but don’t worry, it’s always up to you whether to take part!
        </p>

        <p className='mb-4'>
          We will also remind you by email or other correspondence at least once every 12 months about your participation in this project, your right to withdraw, and provide you with an opportunity to update your information.
        </p>
      </SubSection>

      <SubSection title='Our Business Model'>
        <p className='mb-4'>
          The value of Sequence Bio’s research is in the findings and insights we hope to generate - not the data itself. Our business model is based on researching the information donated by participants to find potentially important patterns and insights. If our researchers develop these findings and insights, we will then work with approved, experienced partners to invest in these findings and bring new treatments and medicines to market. By leading the early stages of this research and development process, Sequence Bio can, like other biotechnology companies, capture a share of the long-term value of the medicines through licensing or other transactions.
        </p>

        <p className='mb-4'>
          Individual data or data that identifies you will not be sold, and we will never sell your saliva sample. We will only use your data in ways which you expressly agree to.
        </p>
      </SubSection>
    </div>
  </>
);

export default TimeWillTell;
