import React from 'react';
import ResearchSection from './research/section';
import spitImage from '../../images/saliva-sample.png';
import medicalRecordsImage from '../../images/paper-medical-records.png';
import protectingYouImage from '../../images/protecting-you.png';
import researchImage from '../../images/research.png';

const Research = () => (
  <>
    <div className='bg-white lg:bg-gradient-b-grey-100-to-transparent lg:pt-24'>
      <div className='container mx-auto px-8 flex flex-col lg:flex-row'>
        <h2 className='font-sans font-bold text-blue-500 text-4xl lg:text-5xl leading-very-tight mb-10'>
          How our research works
        </h2>
      </div>
    </div>

    <div className='container mx-auto px-8'>
      <ResearchSection title='Spit!' image={spitImage}>
        <p className='mb-4'>
          <span className='text-black font-semibold'>With less than half a teaspoon of your saliva (spit), we can extract your DNA and begin to learn about your genetic makeup with a process called genotyping.</span>
          <br />
          Genotyping reads specific parts in your DNA where genetic changes are known to occur. We can also sequence your DNA, which reads your entire genetic code and all the changes that may exist.
        </p>

        <p>
          Our DNA analysis will be performed in an approved Canadian genomics laboratory. Genotyping will be performed using Applied Biosystem’s Axiom Precision Medicine Research Array (PMRA) on the GeneTitan and Whole Genome Sequencing will occur on Illumina’s NovaSeq 6000 Sequencing System.
        </p>
      </ResearchSection>

      <ResearchSection title='Medical records' image={medicalRecordsImage} orientation='right' containerMargins='mb-6'>
        <p className='mb-4'>
          <span className='text-black font-semibold'>To get a more complete picture of Newfoundland and Labrador, we are asking for permission to access all your medical records.</span>
        </p>

        <p>
          <a href='/privacy' className='text-blue hover:underline'>
            Learn more about how we will protect this information.
          </a>
        </p>
      </ResearchSection>

      <ResearchSection title='Our research' image={researchImage} titlePadding='pt-0'>
        <p className='mb-4 text-black font-semibold'>
          Your saliva contains DNA, and thanks to advances in science and technology, researching human DNA is becoming a more affordable and accurate method for developing new medicines.
        </p>

        <p className='mb-4'>
          By using advanced statistical and computational analysis on our custom research platform, we hope to learn more about what makes this province unique by looking for common patterns in lots of people’s DNA. We hope to identify genetic changes that can alter the function of genes (almost like a typo in our genetic code), and look for how those patterns relate to our health. We hope this helps explain why some people are more likely to get sick, or why some medicines work better on some people, but not others. This could help us achieve our goal of discovering better, safer medicines and improving how we treat and prevent diseases.
        </p>

        <p className='italic text-xs'>
          Because the NL Genome Project is a pilot, there is no guarantee it will lead to any new discoveries. There are limits to what we can learn.
        </p>
      </ResearchSection>

      <ResearchSection title='Protecting you' image={protectingYouImage} orientation='right'>
        <p className='mb-4 text-black font-semibold'>
          The NL Genome Project is committed to the highest levels of security and protects data with the same best-practice measures used by leading banks, hospitals, and governments around the world.
        </p>

        <p className='mb-4'>
          Information that identifies you, like your name or birthday, is not used for research and is locked away in a separate encrypted database. Instead, you will only be identified by a unique Study Code when research is performed.
        </p>

        <p>
          When we collaborate with other researchers, they will not download or transfer data. We bring them to the data by providing controlled and secure access to our research platform.
        </p>
      </ResearchSection>
    </div>
  </>
);

export default Research;
