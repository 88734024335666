import React from 'react';
import PropTypes from 'prop-types';

const SubSection = ({ children, title, image, orientation }) => (

  <div className='lg:w-2/6 py-8 px-5'>
    <h2 className='font-sans font-semibold text-grey-500 text-xl leading-very-tight mb-4'>
      {title}
    </h2>

    <div className='font-sans font-normal text-grey leading-snug text-sm'>
      {children}
    </div>
  </div>

);

SubSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  image: PropTypes.string,
  orientation: PropTypes.string,
};

SubSection.defaultProps = {
  image: '',
  title: '',
  orientation: '',
};

export default SubSection;
